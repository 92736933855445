body {
  /* background-color: black; */
  background-image: url("../public/background.jpeg");
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center h-12 w-12 mt-2 mb-2 shadow-lg
        hover:text-gray-700 hover:bg-[white] rounded-3xl hover:rounded-xl transition-all duration-200
        ease-linear cursor-pointer;
  }
  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md text-gray-700 
    bg-[white] text-xs font-bold transition-all duration-100 scale-0 origin-bottom mt-28 -ml-10;
  }

  body {
    margin: 0;
  }
  .testDiv {
    position: relative;
    padding-top: 200px;
  }
}
